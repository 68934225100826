<script lang="ts">
  import { goto } from '../utils/navigation';
  import type { Channel } from '../firebase/api/channel';
  import type { FeedAbstract } from '../firebase/common/feed';
  import type { Plan } from '../firebase/common/plan';
  import IconButton from '@smui/icon-button';
  import { checkoutPlan } from '../utils/checkout';
  import { signingIn } from '../firebase/auth';
  import defaultIcon from '../assets/default-icon.png';

  export let owner: boolean = false;
  export let currentCID: string | null = null;
  export let channel: Channel;
  export let feed: FeedAbstract;
  export let requiredPlan: Plan | null;
  export let currentPlan: Plan | null;
  export let narrow: boolean = false;
  export let showChannelInfo: boolean = false;

  let leadingText: boolean;
  $: leadingText = feed.leading_text.length > 0;

  let width: number;
</script>

<div class="card" class:narrow>
  {#if narrow}
    <a href="/c/{channel.display_name}/f/{feed.id}">
      <div class="cover-img" bind:offsetWidth={width} style:height="{(width * 3) / 2}px">
        <img src={feed.thumbnail} alt="cover" loading="lazy" />
      </div>
    </a>
  {:else}
    <div class="cover-img">
      {#if owner}
        <IconButton
          class="material-symbols-outlined owner-edit"
          on:click={() => {
            goto(`/feeds/${feed.id}`);
          }}
        >
          edit
        </IconButton>
      {/if}
      <a href="/c/{channel.display_name}/f/{feed.id}">
        <img src={feed.thumbnail} alt="cover" loading="lazy" />
      </a>
    </div>
  {/if}
  <div class="information">
    <div class="info-header">
      {#if showChannelInfo}
        <div class="channel">
          <a href="/c/{channel.display_name}">
            <div class="avatar" style:background-image="url({channel.icon ?? defaultIcon})" />
            <div class="name">{channel.name}</div>
          </a>
        </div>
      {/if}
      <div class="published-at">
        <time datetime={feed.published_at.toISOString()}>
          {feed.published_at.toLocaleString()}
        </time>
      </div>
    </div>
    <div class="content">
      <div class="title">
        <h3>{feed.title}</h3>
      </div>
      {#if leadingText}
        <p class="leading-text">
          {feed.leading_text}
        </p>
      {/if}
      <div class="action-footer" class:with-leading-text={leadingText}>
        {#if owner}
          <div class="read-more-wrapper">
            <a href="/c/{channel.display_name}/f/{feed.id}" class="read-more">
              プレビュー
              {#if requiredPlan !== null}
                ({requiredPlan.price}円/月)
              {:else}
                (全体公開)
              {/if}
            </a>
          </div>
        {:else if requiredPlan === null}
          <div class="read-more-wrapper">
            <a href="/c/{channel.display_name}/f/{feed.id}" class="read-more">もっと見る</a>
          </div>
        {:else if currentPlan === null}
          <div class="read-more-wrapper">
            <a
              role="button"
              tabindex="-1"
              href="."
              class="read-more"
              on:click|preventDefault={currentCID
                ? checkoutPlan(currentCID, channel.id, requiredPlan.price)
                : () => signingIn()}
              on:keydown={(e) => e.key === 'Enter' && e.target instanceof HTMLElement && e.target.click()}
            >
              {requiredPlan?.price}円/月
            </a>
          </div>
          {#if !narrow}
            <div class="catch-copy">
              <div class="fukidashi">
                <div class="text">
                  <span class="plan-name">{requiredPlan.name}</span>を購読して、<wbr /><span class="channel-name"
                    >{channel.name}</span
                  >を支援しよう
                </div>
              </div>
            </div>
          {/if}
        {:else if currentPlan.price >= feed.minimum_plan}
          <div class="read-more-wrapper">
            <a href="/c/{channel.display_name}/f/{feed.id}" class="read-more">もっと見る</a>
          </div>
        {:else}
          <div class="read-more-wrapper">
            <a
              role="button"
              tabindex="-1"
              href="."
              class="read-more"
              on:click|preventDefault={checkoutPlan(currentCID ?? 'never', channel.id, requiredPlan.price)}
              on:keydown={(e) => e.key === 'Enter' && e.target instanceof HTMLElement && e.target.click()}
            >
              {requiredPlan?.price}円/月 (+{requiredPlan.price - currentPlan.price}円/月)
            </a>
          </div>
          {#if !narrow}
            <div class="catch-copy">
              <div class="fukidashi">
                <div class="text">
                  支援プランを
                  <span class="plan-name">{requiredPlan.name}</span>
                  にアップグレードして、<wbr />
                  <span class="channel-name">{channel.name}</span>
                  をもっと支援しよう
                </div>
              </div>
            </div>
          {/if}
        {/if}
      </div>
    </div>
  </div>
</div>

<style lang="scss">
  $sp-width: 456px;
  $tb-width: 880px;

  .card.narrow {
    width: auto;
    position: relative;

    .information {
      position: absolute;
      background: rgba(255, 255, 255, 0.75);
      width: calc(100% - 3rem);
      height: 12rem;
      padding: 0.5rem;
      left: 1rem;
      bottom: 2rem;
      border: solid 1px rgba(255, 255, 255, 0.75);
      border-radius: 0 0 1rem 1rem;
      display: flex;
      flex-flow: column;

      .channel {
        max-width: 10rem;
      }

      time {
        font-size: 60%;
        color: var(--black);
      }

      .content {
        justify-content: space-between;
        margin: unset;
        padding-bottom: 1rem;
      }
    }
  }

  .channel {
    overflow: hidden;
    a {
      display: flex;
      align-items: center;
      grid-column-gap: 0.75rem;
      text-decoration: none;
    }

    .avatar {
      width: 3rem;
      height: 3rem;
      background: no-repeat center/cover;
      border-radius: 9in;
      flex-shrink: 0;
    }

    .name {
      text-overflow: ellipsis;
      word-break: keep-all;
      overflow: hidden;
      white-space: nowrap;
      color: var(--text-gray);
      font-weight: bold;
    }
  }

  .card {
    display: flex;
    flex-direction: column;
    width: calc(100% - 4rem);
    height: auto;
    overflow: hidden;
    margin: 0 auto;
    background-color: var(--bg-sub);
    padding: 1rem 1rem 2rem;
    border-radius: 0.5rem;
    &:not(:last-of-type) {
      margin-bottom: 2rem;
    }

    &.narrow {
      width: auto;
      margin: 0;

      .cover-img {
        width: 100%;
        overflow: hidden;
        pointer-events: none;
        user-select: none;
        position: relative;
        border-radius: 1rem;

        img {
          width: 100%;
          height: 100%;
        }
      }

      p.leading-text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    :global(.owner-edit) {
      position: absolute;
      pointer-events: all;
      right: 0;
      background: #ffffffc0;
      border-radius: 5rem;
    }

    p.leading-text {
      padding: 0;
      margin: 0;
      line-height: 1.65;
      font-size: 90%;
      color: var(--black);
    }

    .read-more-wrapper {
      text-align: center;
      padding: 0.5rem 2rem 0.65rem;
      background-color: var(--primary-color);
      border-radius: 0.2rem;
      cursor: pointer;
      transition: opacity 0.3s;
      &:hover {
        opacity: 0.6;
      }
    }

    a.read-more {
      text-decoration: none;
      font-weight: bold;
      color: #fff;
      display: block;
    }

    .action-footer {
      padding-top: 1rem;
      margin: auto 0 -1rem;

      .catch-copy {
        margin-top: 1.5rem;

        .fukidashi {
          margin: auto;
          width: fit-content;
          max-width: 36rem;
          padding: 0.75rem 1.5rem;
          font-size: 1rem;
          border: solid 2px var(--primary-color);
          border-radius: 0.5rem;
          position: relative;
          font-weight: bold;
          color: var(--primary-color);
          background-color: #fff;

          &::before {
            content: '';
            display: block;
            width: 1.5rem;
            height: 1.5rem;
            background-color: var(--primary-color);
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, calc(-50% - 0.1rem)) rotate(45deg);
            z-index: 1;
          }
          &::after {
            content: '';
            display: block;
            width: 1.5rem;
            height: 1.5rem;
            background-color: white;
            position: absolute;
            top: 0;
            top: 0%;
            left: 50%;
            transform: translate(-50%, calc(-50% + 0.1rem)) rotate(45deg);
            z-index: 1;
          }
        }

        .text {
          position: relative;
          z-index: 2;
          text-align: center;

          span {
            font-size: 110%;
          }
        }
      }
    }

    .cover-img {
      width: 100%;
      height: fit-content;
      // aspect-ratio: 16/9;
      border-radius: 0.5rem;
      overflow: hidden;
      pointer-events: none;
      user-select: none;
      position: relative;
      background-color: var(--bg-sub);
      border: 1px solid var(--border-gray);

      a {
        display: block;
        pointer-events: all;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
        border-radius: 0.5rem;
      }
    }

    .info-header {
      display: flex;
      align-items: center;
      grid-column-gap: 0.5rem;
      justify-content: space-between;
      margin-top: 0.8rem;
      margin-bottom: 0.8rem;
      .published-at {
        font-size: 0.9rem;
        color: var(--text-gray);
        flex-shrink: 0;
      }
    }

    .title {
      margin: 0;
      text-align: left;

      h3 {
        color: black;
        margin: 0;
        padding-left: 0;
        padding-bottom: 0.25rem;
        border-bottom: none;
        color: var(--black);
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      margin-top: auto;
    }
  }
  @media screen and (max-width: #{$tb-width}) {
    :global(.sp-paper) {
      padding: 1rem;
    }
    .card {
      padding: 1rem;
      margin: 0;
      width: calc(100% - 2rem);
      border-radius: 0.5rem;
      padding-bottom: 1rem;
      background-color: #fff;
      &:not(:last-of-type) {
        margin-bottom: 0.5rem;
        padding-bottom: 1rem;
        border-bottom: none;
      }
      .action-footer {
        .catch-copy {
          margin-top: 1.5rem;
        }
      }

      .information.information {
        bottom: 1rem;
      }

      .info-header {
        padding: 1rem 0 0;
        width: 100%;
      }

      .cover-img {
        width: 100%;
        // aspect-ratio: 16/9;
        border-radius: 0.5rem 0.5rem 0 0;
        > img {
          border-radius: 0;
        }
      }

      .title {
        margin: 0;
        text-align: left;
      }
      .action-footer {
        padding-top: 1rem;
        margin-bottom: 0;
      }
    }

    .card :global(button) {
      width: 100%;
    }
    .content {
      padding: 0;
    }
  }
</style>
